import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  en: {
    translation: {
      "Home": "Home",
      "About us": "About us",
      "Services": "Services",
      "Projects": "Projects",
      "Contact": "Contact",
      "All rights reserved.": "All rights reserved."
    }
  },
  tk: {
    translation: {
      "Home": "Baş",
      "About us": "Hakynda",
      "Services": "Hyzmatlar",
      "Projects": "Taslamalar",
      "Contact": "Habarlaşmak",
      "All rights reserved.": "Ähli hukuklary goragly."
    }
  }
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "en",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

  export default i18n;