import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/layout"
import Header from "../components/Header/header"
import Footer from "../components/Footer/footer"
import Section from "../components/Section/section"
import '../i18n';

export default function Template({ data }) {
    const post = data.markdownRemark
    return <Layout><Header lang={post.frontmatter.lang} slug={post.frontmatter.slug}/>
        <main id="main">
            <Section title={post.frontmatter.title}>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </Section>
        </main>
        <Footer /></Layout>
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        lang
        slug
      }
    }
  }
`